/*
--rich-black-fogra-39: #0a0903ff;
--red: #ff0000ff;
--orange: #ff8200ff;
--mikado-yellow: #ffc100ff;
--banana-mania: #ffeaaeff;
*/


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #FFC230;
  width:100vw;
  height:100vh;
  padding:0;
  margin:0;
  overscroll-behavior-y: none;
  overflow:hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.layoutContainer {
  width:100vw;
  height:100vh;
  padding:0;
  margin:0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 70px 70px 1fr;
  overflow:hidden;
}

.layoutHeadRow {
  grid-column: 1;
  grid-row: 1;
  overflow: hidden;
  padding: 5px 16.5px;
  justify-content: center;
}

.layoutSubRow {
  grid-column: 1;
  grid-row: 2;
  overflow: hidden;
  padding: 5px 16.5px;
  text-align: center;
}

.layoutMainRow {
  grid-column: 1;
  grid-row: 3;
  overflow: scroll;
  padding: 5px 16.5px;
}

.MuiPickerStaticWrapper-root {
  background-color: transparent !important;
}
